import React, { useEffect, useState } from "react";
import "./style.css";
import ProductList from "@/componnents/productList";
import muscel from "@/assets/images/muscel.png";
import Breadcrumb from "@/componnents/breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "@/redux/features/product/productAction";
import {
  selectLoading,
  selectPaginate,
  selectProducts,
} from "@/redux/features/product/productSlice";
import useCurrency from "@/hook/useCurrency";
import Pagination from "@/componnents/paginate";
import { NavLink, useParams } from "react-router-dom";
import { selectDanhmuc } from "../../redux/features/danhmuc/danhmucSlice";

export default function Shop() {
  const [sortValue, setSortValue] = useState("priceHighToLow");
  const [tags, setTags] = useState([]);
  const [breadcrumb, setBreadcrumb] = useState([]);

  const { slug } = useParams();
  const products = useSelector(selectProducts);
  const loading = useSelector(selectLoading);
  const paginate = useSelector(selectPaginate);
  const danhmucs = useSelector(selectDanhmuc);
  const formatCurrency = useCurrency();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProducts(slug, 1, 12, "", 100000, 4000000, ""));
    if (danhmucs.length > 0) {
      const danhmuc = danhmucs.find((x) => x.slug === slug);
      setBreadcrumb([
        {
          name: "Danh mục",
          link: "danh-mục",
        },
        {
          name: danhmuc.title,
          link: danhmuc.slug,
        },
      ]);
      setTags(danhmuc.tags);
    }
  }, [dispatch, slug, danhmucs, setTags]);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [loading]);
  const nextPage = () => {
    dispatch(getProducts(slug, paginate.nextPage, 12, "", 100000, 4000000, ""));
  };

  const prevPage = () => {
    dispatch(getProducts(slug, paginate.prevPage, 12, "", 100000, 4000000, ""));
  };

  const selectSpecificPage = (page) => {
    dispatch(getProducts(slug, page, 12, "", 100000, 4000000, ""));
  };
  const handleSortChange = (event) => {
    setSortValue(event.target.value);
    dispatch(getProducts(slug, 1, 12, event.target.value, 100000, 4000000, ""));
  };

  return (
    <>
      <Breadcrumb data={breadcrumb} title="Danh mục"/>
      <div className="flex lg:flex-row flex-col lg:space-x-10 lg:space-y-0 space-y-10">
        <div className="lg:w-4/5 space-y-10 relative">
          {loading ? (
            <div className="absolute left-1/2 top-1/2 loader-product"></div>
          ) : (
            <>
              <div className="flex md:items-center md:flex-row flex-col md:justify-between py-3 md:space-y-0 space-y-10">
                {paginate && (
                  <span className="text-gray-300">
                    Hiển thị{" "}
                    {paginate.currentPage * paginate.results -
                      paginate.results +
                      1}
                    -{paginate.currentPage * paginate.results} của{" "}
                    {paginate.results} kết quả
                  </span>
                )}
                <form className="woocommerce-ordering" method="get">
                  <select
                    className="bg-transparent text-gray-300 w-80 email-input "
                    onChange={handleSortChange}
                    value={sortValue}
                  >
                    <option value="priceHighToLow">Giá giảm dần</option>
                    <option value="priceLowToHigh">Giá tăng dần</option>
                    <option value="date">Đăng gần đây</option>
                  </select>
                </form>
              </div>
              <ProductList products={products} />
              {paginate && (
                <Pagination
                  paginate={paginate}
                  nextPage={nextPage}
                  prevPage={prevPage}
                  selectPage={selectSpecificPage}
                />
              )}
            </>
          )}
        </div>
        <div className="lg:w-1/5 space-y-12">
          <div className="space-y-4">
            <h2 className="text-lg uppercase font-bold">Danh mục</h2>
            {danhmucs.map((e, i) => (
              <NavLink
                className="font-light hover:text-white cursor-pointer text-gray-400 block danhmuc-link"
                key={i}
                to={`/danh-muc/${e.slug}`}
              >
                {e.title}
              </NavLink>
            ))}
          </div>
          <div className="space-y-4">
            <h2 className="text-lg uppercase font-bold">TAGS</h2>
            <div className="space-x-2 text-gray-300">
              {tags.length > 0 &&
                tags.map((e, i) => (
                  <span
                    className="line-through hover:no-underline cursor-pointer text-gray-400 pr-2"
                    key={i}
                  >
                    {e.title}
                  </span>
                ))}
            </div>
          </div>
          <div className="space-y-6">
            <h2 className="text-lg uppercase font-bold">Sản phẩm mới</h2>
            <div className="flex space-x-4">
              <img
                src={muscel}
                className="border border-gray-300 w-2/5 px-4 object-contain bg-gradient"
                alt=""
              />
              <div className="w-3/5 space-y-2">
                <h2 className="uppercase font-bold">Gao muscel</h2>
                <span className="text-gray-300 inline-block">
                  {formatCurrency(2000000)}
                </span>
              </div>
            </div>
            <div className="flex space-x-4">
              <img
                src={muscel}
                className="border border-gray-300 w-2/5 px-4 object-contain bg-gradient"
                alt=""
              />
              <div className="w-3/5 space-y-2">
                <h2 className="text-lg uppercase font-bold">Gao muscel</h2>
                <span className="text-gray-300 inline-block">
                  {formatCurrency(2000000)}
                </span>
              </div>
            </div>
            <div className="flex space-x-4">
              <img
                src={muscel}
                className="border border-gray-300 w-2/5 px-4 object-contain bg-gradient"
                alt=""
              />
              <div className="w-3/5 space-y-2">
                <h2 className="text-lg uppercase font-bold">Gao muscel</h2>
                <span className="text-gray-300 inline-block">
                  {formatCurrency(2000000)}
                </span>
              </div>
            </div>
          </div>
          <div className="space-y-4">
            <h2 className="text-lg uppercase font-bold">GALLERY</h2>
            <div className="grid grid-cols-3 gap-2">
              {[...Array(6)].map((e, i) => (
                <img
                  key={i}
                  className="px-1 object-contain bg-gradient"
                  src={muscel}
                  alt=""
                />
              ))}
            </div>
          </div>
          <div className="space-y-4">
            <h2 className="text-lg uppercase font-bold">FOLLOW US</h2>
            <ul className="flex items-center  space-x-4">
              <li className="w-7">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19">
                  <path
                    fill="#fff"
                    d="M16.743,0.6H2.306A1.97,1.97,0,0,0,.337,2.569V17.006a1.97,1.97,0,0,0,1.969,1.969H7.935V12.728H5.351V9.787H7.935V7.546a3.589,3.589,0,0,1,3.842-3.957,15.654,15.654,0,0,1,2.277.2v2.5H12.772a1.47,1.47,0,0,0-1.658,1.589V9.787h2.821l-0.451,2.94h-2.37v6.247h5.629a1.97,1.97,0,0,0,1.969-1.969V2.569A1.97,1.97,0,0,0,16.743.6Z"
                  ></path>
                </svg>
              </li>
              <li className="w-9">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 21">
                  <path
                    fill="#fff"
                    d="M27.7 4.7c0-2.4-1.8-4.4-4-4.4-3-.1-6-.2-9.2-.2h-1c-3.1 0-6.2.1-9.2.2-2.2 0-4 2-4 4.4C.2 6.6.1 8.5.1 10.5c0 1.9.1 3.8.2 5.8 0 2.4 1.8 4.4 4 4.4 3.1.1 6.4.2 9.6.2 3.3 0 6.5-.1 9.6-.2 2.2 0 4-2 4-4.4.1-1.9.2-3.9.2-5.8.2-1.9.1-3.8 0-5.8zM11.4 15.8V5.2l7.8 5.3-7.8 5.3z"
                  ></path>
                </svg>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
