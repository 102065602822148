import React, { useEffect, useState } from "react";
import Breadcrumb from "@/componnents/breadcrumb";
import useCurrency from "@/hook/useCurrency";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCartAmount,
  selectCartList,
} from "@/redux/features/cart/cartSlice";
import { handleQuantity, handleRemove } from "@/redux/features/cart/cartAction";

export default function Cart() {
  const formatCurrency = useCurrency();
  const dispatch = useDispatch();
  const amount = useSelector(selectCartAmount);
  const cartList = useSelector(selectCartList);
  const [updatedCart, setUpdatedCart] = useState([]);
  const data = [
    {
      name: "Giỏ hàng",
      link: "gio-hang",
    },
  ];
  useEffect(() => {
    setUpdatedCart([...cartList]);
  }, [cartList]);
  const handleIncrease = (index) => () => {
    const updated = updatedCart.map((item, idx) =>
      idx === index ? { ...item, cartNum: item.cartNum + 1 } : item
    );
    setUpdatedCart(updated);
  };

  const handleDecrease = (index) => () => {
    const updated = updatedCart.map((item, idx) =>
      idx === index && item.cartNum > 0
        ? { ...item, cartNum: item.cartNum - 1 }
        : item
    );
    setUpdatedCart(updated);
  };

  const handleChange = (index) => (event) => {
    const { value } = event.target;
    const updated = updatedCart.map((item, idx) =>
      idx === index ? { ...item, cartNum: parseInt(value, 10) } : item
    );
    setUpdatedCart(updated);
  };

  const handleUpdateCart = () => {
    updatedCart.forEach((item, index) => {
      dispatch(handleQuantity(item)); // Update quantity in Redux store
    });
  };
  return (
    <>
      <Breadcrumb data={data} title="Giỏ hàng"/>
      {cartList.length > 0 ? (
        <div className="space-y-10">
          <div className="md:max-w-full w-[100vw - 48px] md:w-full overflow-auto">
            <table className="w-full table-auto overflow-auto">
              <thead>
                <tr className="border-b border-gray-300 text-left">
                  <th className="px-4 py-3 w-6"></th>
                  <th className="px-4 py-3 md:w-40 hidden md:block"></th>
                  <th className="px-4 py-3 uppercase text-lg font-bold text-nowrap">
                    Sản phẩm
                  </th>
                  <th className="px-4 py-3 uppercase text-lg font-bold">Giá</th>
                  <th className="px-4 py-3 uppercase text-lg font-bold text-nowrap">
                    Số lượng
                  </th>
                  <th className="px-4 py-3 uppercase text-lg font-bold">
                    Tạm tính
                  </th>
                </tr>
              </thead>
              <tbody>
                {updatedCart.map((e, i) => (
                  <tr className="border-b border-gray-300 text-left" key={i}>
                    <td
                      className="px-4 py-3 cursor-pointer"
                      onClick={() => {
                        dispatch(handleRemove(e));
                      }}
                    >
                      x
                    </td>
                    <td className="px-4 py-3 hidden md:block">
                      <img
                        className="w-20 p-2 border border-gray-300 m-2"
                        src={e.image}
                        alt="Gao muscel"
                      />
                    </td>
                    <td className="px-4 py-3 font-bold uppercaes">
                      <Link
                        to={`/san-pham/${e.slug}`}
                        className="whitespace-nowrap"
                      >
                        {e.name}
                      </Link>
                    </td>
                    <td className="px-4 py-3 text-gray-400">
                      {formatCurrency(e.price)}
                    </td>
                    <td className="px-4 py-3 ">
                      <div className="w-24 relative">
                        <span
                          className="absolute top-0 w-8 h-1/2 text-center right-0 border border-gray-300 cursor-pointer"
                          onClick={handleIncrease(i)}
                        >
                          +
                        </span>
                        <span
                          className="absolute bottom-0 w-8 h-1/2 text-center right-0 border border-gray-300 cursor-pointer"
                          onClick={handleDecrease(i)}
                        >
                          -
                        </span>
                        <input
                          type="text"
                          value={e.cartNum}
                          onChange={handleChange(i)}
                          className="inline-block text-center px-2 py-3 text-lg w-16 border border-gray-300 bg-transparent"
                        />
                      </div>
                    </td>
                    <td className="px-4 py-3 text-gray-400">
                      {formatCurrency(e.price * e.cartNum)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="space-y-8 ">
            <div className="w-ful flex justify-end">
              <button
                className="button-submit text-nowrap w-auto flex justify-end"
                onClick={handleUpdateCart}
              >
                cập nhật
              </button>
            </div>
            <div className="w-ful flex justify-end">
              <div className=" space-y-6">
                <h2 className="text-2xl uppercase font-bold text-right">
                  Tổng đơn hàng
                </h2>
                <div className="flex justify-end ">
                  {" "}
                  <table className="md:w-full w-[100vw - 48px] table-auto overflow-auto">
                    <thead>
                      <tr className="border-b border-gray-300 text-left">
                        <th className="px-4 py-3"></th>
                        <th className="px-4 py-3"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="border-b border-gray-300 text-left">
                        <td className="px-4 py-3 text-lg uppercase font-bold">
                          Tạm tính
                        </td>
                        <td className="px-4 py-3 text-gray-400">
                          {formatCurrency(amount)}
                        </td>
                      </tr>
                      <tr className="border-b border-gray-300 text-left">
                        <td className="px-4 py-3 text-lg uppercase font-bold">
                          Tổng
                        </td>
                        <td className="px-4 py-3 text-white">
                          {formatCurrency(amount)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="flex justify-end  md:!w-auto !w-[100vw - 48px]">
                  <Link
                    to="/thanh-toan"
                    className="button-submit text-nowrap md:!w-full !w-11/12"
                  >
                    tiến hành thanh toán
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center space-y-8 h-96 ">
          <h2 className="lg:text-3xl text-xl text-center font-bold uppercase">
            giỏ hàng hiện tại của bạn đang trống
          </h2>
          <Link
            to="/danh-muc/gaoranger"
            className="button-submit text-nowrap"
            type="submit"
          >
            Trở lại danh mục
          </Link>
        </div>
      )}
    </>
  );
}
