import React, { useEffect, useRef } from "react";
import Breadcrumb from "../../componnents/breadcrumb";

export default function About() {
  const imgRef = useRef(null);
  const wrapRef = useRef(null);
  const data = [
    {
      name: "Về chúng tôi",
      link: "ve-chung-toi",
    },
  ];
  useEffect(() => {
    const wrapElement = wrapRef.current;
    const imgElement = imgRef.current;
    const handleScroll = () => {
      const { bottom } = wrapElement.getBoundingClientRect();
      const scrollTop = window.scrollY;
      imgElement.classList.add("about-sticky");
      imgElement.classList.remove("about-absolute");
      if (scrollTop >= bottom + 500) {
        imgElement.classList.remove("about-sticky");
        imgElement.classList.add("about-absolute");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <Breadcrumb data={data} title={"Về chúng tôi"} />
      <div className="flex lg:flex-row flex-col  lg:px-20 lg:space-x-10 lg:space-y-0 space-y-8  w-full">
        <div className="lg:w-1/3 ">
          <div className="h-full relative" ref={wrapRef}>
            <img
              className="lg:w-[370px] 2xl:w-[432px]"
              ref={imgRef}
              src="https://scontent.fsgn2-9.fna.fbcdn.net/v/t39.30808-1/178900657_1001745090599204_8019842345720844535_n.jpg?stp=dst-jpg_s480x480&_nc_cat=106&ccb=1-7&_nc_sid=0ecb9b&_nc_ohc=fJs1wORrmpMQ7kNvgF2YLrk&_nc_ht=scontent.fsgn2-9.fna&oh=00_AYBoDBMybNtdee-9w5dCdH8mk5e72qFl5MGAdyNx6QztlQ&oe=66B3A7DF"
              alt=""
            />
          </div>
        </div>
        <div className="lg:w-2/3 space-y-20">
          <div className="flex space-x-10 items-center justify-between relative md:pl-10 pl-2">
            <span className="uppercase text-lg absolute -left-12 top-16 translate-rotate">
              About me
            </span>
            <div className="space-y-4">
              <h2 className="md:text-3xl text-xl font-bold uppercase">
                Siêu nhân và Anime
              </h2>
              <p className="text-justify">
                Chào mừng bạn đến với cửa hàng của chúng tôi – nơi chuyên cung
                cấp các sản phẩm siêu nhân và anime chất lượng cao. Chúng tôi tự
                hào mang đến cho người hâm mộ những sản phẩm độc đáo và phong
                phú, từ mô hình, tượng, đồ chơi.
              </p>
            
            </div>
          </div>
          <div className="w-full md:flex md:space-x-10 space-y-4 md:space-y-0 md:pl-20 pl-2">
            <div className="md:w-1/2 space-y-3 flex flex-col">
              <h2 className="font-bold uppercase text-xl">Chuyên cung cấp </h2>
              <span className="text-gray-400 inline-block">Siêu nhân Gao</span>
              <span className="text-gray-400 inline-block">
                Siêu nhân Cuồng Phong
              </span>
              <span className="text-gray-400 inline-block">
                Siêu nhân Khủng Long
              </span>
              <span className="text-gray-400 inline-block">
                Các loại mô hình khác
              </span>
              <span className="text-gray-400 inline-block">
                Thu mua mô hình
              </span>
            </div>
            <div className="md:w-1/2 space-y-3 flex flex-col">
              <h2 className="font-bold uppercase text-xl">Sữa chữa mô hình</h2>
              <span className="text-gray-400 inline-block">
                Fix các lỗi hư gãy
              </span>
              <span className="text-gray-400 inline-block">
                Cung cấp phụ kiên thay thế
              </span>
              <span className="text-gray-400 inline-block">
                Mạ và sơn lại mô hình
              </span>
            </div>
          </div>
          <div className="space-y-8">
            <img
              src="https://artorias.qodeinteractive.com/wp-content/uploads/2022/10/p-gallery-img-1.jpg"
              alt=""
            />
            <img
              src="https://artorias.qodeinteractive.com/wp-content/uploads/2022/10/p-gallery-img-4.jpg"
              alt=""
            />
            <img
              src="https://artorias.qodeinteractive.com/wp-content/uploads/2022/10/p-gallery-img-3.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
}
